<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Voter Complaints</div>
  </div>
  <div class="company-section-outer">
    <div class="layout-content-section">
      <div
        class="
          content-header-section
          d-flex
          justify-content-start
          align-items-center
          mb-3
          border-bottom
        "
      >
        <div class="content-subheader-box">Total Open</div>
      </div>
      <div class="company-detail-box-outer">
        <div class="row g-2">
          <div class="col-lg-2 col-md-2 col-12 voter-profile-static-col">
            <div class="customer-label-group">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column me-3">
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="far fa-star text-star-blank"></i>
                </div>
                <div>
                  <img
                    src="/assets/images/profile-8.jpg"
                    class="rounded bg-light me-auto d-block"
                    alt=""
                    width="90"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12 voter-name-static-col">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Name</label>
                  <div class="from-label-value">Mr. Suresh Raosaheb Joshi</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Area | Pin Code</label>
                  <div class="from-label-value">Nagala Park | 416012</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-1">
                  <label class="form-label">Complaint : </label
                  ><label class="from-label-value ms-1"
                    >Municipal Corporation Related</label
                  >
                  <div class="form-label-description">
                    Street cleaning is not happening in our Area. We have given multiple complaints to KMC Still no use. Kindly help.
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <span class="form-label">Last Remark : </span
                  ><span class="from-label-value ms-1"
                    >on 29 Nov 2021 @ 10:30 AM by Tushar Salunkhe.</span>
                    <div class="form-label-description">
                    We are in talks with KMC officers regarding this issue. They will visit the area on 5th Dec 2021.</div
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <div class="d-flex justify-content-between">
                    <label class="form-label">Email</label
                    ><span class="complaints-open">Open</span>
                  </div>
                  <div class="from-label-value">joshisuresh@gmail.com</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div
                  class="
                    customer-label-group
                    mb-2
                    d-flex
                    justify-content-between
                  "
                >
                  <div>
                    <label class="form-label">Mobile</label>
                    <div class="from-label-value">+91 9957878534</div>
                  </div>
                  <div class="d-flex align-items-end">
                    <button class="btn custom-outline-call-btn mx-2">
                      <i class="fas fa-phone-alt call-btn-color"></i></button
                    ><button class="btn custom-outline-whatsapp-btn me-2">
                      <i class="fab fa-whatsapp whatsapp-color"></i>
                    </button>
                    <button
                      title="Add Remark"
                      class="btn custom-outline-ellipsis-btn"
                      @click="openFollowupModal()"
                    >
                      <img
                        src="/assets/images/icon/ellipsis-v-icon.svg"
                        width="30"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-detail-box-outer">
        <div class="row g-2">
          <div class="col-lg-2 col-md-2 col-12 voter-profile-static-col">
            <div class="customer-label-group">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column me-3">
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning"></i>
                </div>
                <div>
                  <img
                    src="/assets/images/profile-9.jpg"
                    class="rounded bg-light me-auto d-block"
                    alt=""
                    width="90"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12 voter-name-static-col">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Name</label>
                  <div class="from-label-value">Mr. Balu Ganpat More</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Area | Pin Code</label>
                  <div class="from-label-value">Nagala Park | 416012</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-1">
                  <label class="form-label">Complaint : </label
                  ><label class="from-label-value ms-1"
                    >MSEB Electricity Related</label
                  >
                  <div class="form-label-description">
                    We are facing huge problems in our area from MSEB related to billing, Please help regarding this.
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <span class="form-label">Last Remark : </span
                  ><span class="from-label-value ms-1"
                    >on 29 Nov 2021 @ 10:30 AM by Tushar Salunkhe.</span>
                    <div class="form-label-description">
                    MSEB has assured us that, they will be sending a senior engineer to audit the extra bills of the customers of that area. We will again take follow-up on 10th Dec 2021.</div
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <div class="d-flex justify-content-between">
                    <label class="form-label">Email</label
                    ><span class="complaints-open">Open</span>
                  </div>
                  <div class="from-label-value">balumore123@gmail.com</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div
                  class="
                    customer-label-group
                    mb-2
                    d-flex
                    justify-content-between
                  "
                >
                  <div>
                    <label class="form-label">Mobile</label>
                    <div class="from-label-value">+91 7455878534</div>
                  </div>
                  <div class="d-flex align-items-end">
                    <button class="btn custom-outline-call-btn mx-2">
                      <i class="fas fa-phone-alt call-btn-color"></i></button
                    ><button class="btn custom-outline-whatsapp-btn me-2">
                      <i class="fab fa-whatsapp whatsapp-color"></i>
                    </button>
                    <button
                      title="Add Remark"
                      class="btn custom-outline-ellipsis-btn"
                      @click="openFollowupModal()"
                    >
                      <img
                        src="/assets/images/icon/ellipsis-v-icon.svg"
                        width="30"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="addfollowupsmodalstatus">
    <div class="modal-dialog modal-md modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Add Followup</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeFollowupModal()"
          ></button>
        </div>
        <AddFollowupModal />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addfollowupsmodalstatus: false,
    };
  },
  methods: {
    openFollowupModal() {
      this.addfollowupsmodalstatus = true;
    },
    closeFollowupModal() {
      this.addfollowupsmodalstatus = false;
    },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.company-detail-box-outer {
  padding: 16px 16px 0;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}
.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}
.customer-label-group {
  margin-bottom: 20px;
}
.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 525px;
}
.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}
.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}
.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}
.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}
.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}
.text-star-blank {
  color: #c1c5c9;
}
.complaints-open {
  background-color: #d8d7fb;
  letter-spacing: 0.26px;
  color: #4a5463;
  padding: 3px 8px 0px;
  border-radius: 9px;
  font-family: "AcuminPro-SemiBold";
  font-size: 11px;
  height: 19px;
}
.form-label-description{
    font-family: 'AcuminPro-Regular';
    font-size: 13px;
    letter-spacing: 0.26px;
    color: #2b2e38;
    line-height: 14px;
}
</style>